import Image from 'next/image'
import FieldlabLogo from '@/images/ri-fieldlab.svg'
import RalstonLogo from '@/images/ralston-logo.svg'
import Head from 'next/head'
import { InlineButton } from '@ralston-instruments/app.fieldlab.components.web/inlineButton'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'

export default function Simple({ children, title }) {
  return (
    <div className="min-h-full flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="flex-1 flex flex-col sm:mx-auto sm:w-full sm:max-w-lg  sm:flex-initial">
        <div className="bg-white pt-8 pb-4 px-4 shadow flex-1 sm:flex-auto sm:rounded-lg sm:px-10 flex flex-col gap-6">
          {title && (
            <Head>
              <title>{title}</title>
            </Head>
          )}
          {/* <div className="sm:mx-auto sm:w-full sm:max-w-md relative">

      </div> */}

          <div className="sm:mx-auto sm:w-full sm:max-w-md flex-1 flex flex-col gap-8">
            <div>
              <Image
                src={RalstonLogo}
                alt="Ralston Instruments"
                sizes="(min-width: 640px) 432px, calc(100vw - 2rem)"
              />
            </div>
            <div>
              <Image
                src={FieldlabLogo}
                alt="Ralston Instruments"
                sizes="(min-width: 640px) 432px, calc(100vw - 2rem)"
              />
            </div>
            <div className="flex-1 flex flex-col">{children}</div>
          </div>
          <div className="flex justify-end">
            <InlineButton
              size="sm"
              componentState="link"
              text="Help & Support"
              Icon={QuestionMarkCircleIcon}
              as="a"
              href={`https://support.ralstoninst.com/`}
              target="_blank"
              rel="noopener noreferrer"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
