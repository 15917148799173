import Simple from '@/components/layouts/Simple'
import { useNavigate } from '@/hooks/useReactRouterHooks'
import { useEffect, useRef } from 'react'
import React, { useMemo } from 'react'
import User from '@/components/molecules/User'
import useFormData from '@/hooks/useFormData'
import FormGroupTextInput from '@/components/molecules/forms/FormGroupTextInput'
import FormGroup from '@/components/molecules/forms/FormGroup'
import { useSession, useUserInfo } from '@/util/session'
import paths from '@/util/paths'
import { useMutateInstance } from '@/util/localApi'
import apiPaths from '@/util/localApi/apiPaths'
import { QueryError } from '@/components/organisms/QueryView'
import { CtaBlock } from '@ralston-instruments/app.fieldlab.components.web/ctaBlock'
import {
  FieldSet,
  FieldSetFields,
} from '@ralston-instruments/app.fieldlab.components.web/fieldSet'
import { Loader } from '@ralston-instruments/app.fieldlab.components.web/loader'

export const CreateAccount = () => {
  const { data: session } = useSession()
  const { user } = session
  const initialFormData = useMemo(
    () => ({ name: '', account_id: user.primary_account_id }),
    [user.primary_account_id]
  )
  const { formData, updateInput } = useFormData(initialFormData)
  const { refetch: refetchUserInfo } = useUserInfo()
  const navigate = useNavigate()

  const mutationQuery = useMutateInstance(apiPaths.serviceLabs(), {
    method: 'POST',
    onSuccess: async (data) => {
      await refetchUserInfo()

      navigate(paths.serviceLab({ serviceLabId: data.data.id }))
    },
  })

  return (
    <>
      {mutationQuery.isError && <QueryError queryModel={mutationQuery} />}
      <form
        autoComplete="off"
        className="relative min-h-[6em] mt-4"
        onSubmit={(e) => {
          e.preventDefault()
          mutationQuery.mutate(formData)
        }}
      >
        {mutationQuery.isLoading || mutationQuery.isSuccess ? (
          <Loader size="3x" />
        ) : (
          <FieldSet
            title="Account Name"
            description="Choose a name for your FieldLab Account. This can be a division or organization name."
          >
            <FieldSetFields>
              <FormGroup labelText="FieldLab Account Owner">
                <div className="p-4 bg-gray-100 border border-gray-300 flex-1 rounded-md">
                  <User linkToProfile={false} />
                </div>
              </FormGroup>
              <FormGroupTextInput
                controlId="name"
                labelText="Service Lab Name"
                errors={mutationQuery.error?.data?.errors}
                formData={formData}
                placeholder={`eg "Ralston Service Lab"`}
                onInputChange={updateInput}
              />
              <CtaBlock
                button={{
                  type: 'submit',
                  text: 'Create Account',
                  disabled: !formData.name,
                }}
                ctaType="primary"
              />
            </FieldSetFields>
          </FieldSet>
        )}
      </form>
    </>
  )
}

export default function Page() {
  const userInfo = useUserInfo()
  const navigate = useNavigate()
  const hasNavigated = useRef(false)

  const availableViews = userInfo?.data?.availableViews

  useEffect(() => {
    if (!hasNavigated.current && availableViews?.length) {
      navigate(availableViews[0].uri)
      hasNavigated.current = true
    }
  }, [availableViews, navigate])

  if (userInfo.isFetching) {
    return (
      <div className="min-h-full flex flex-col">
        <Loader />
      </div>
    )
  }

  if (userInfo.isError) {
    return null
    //  (
    //   <Simple title="Error loading your account">
    //     <Alert componentState="danger">
    //       <h3>There was an error loading your account.</h3>
    //       <p>Please sign in again.</p>
    //       <p>
    //         <Button
    //           size="sm"
    //           text="Sign In"
    //           onClick={() => {
    //             signOut()
    //           }}
    //         />
    //       </p>
    //     </Alert>
    //   </Simple>
    // )
  }

  if (availableViews?.length === 0) {
    return (
      <Simple title="Create your FieldLab Service Lab">
        <CreateAccount />
      </Simple>
    )
  }

  return null
}

Page.auth = true
