import React, { useEffect } from 'react'
import { FormGroup } from './FormGroup'
import { TextInput } from '@ralston-instruments/app.fieldlab.components.web/textInput'

// This is a generic and opinionated composed component
// Feel free to just use FormGroup/TextInput for situations that are not supported

const FormGroupTextInput = ({
  controlId,
  labelText,
  errors,
  tooltip,
  placeholder,
  formData,
  onInputChange,
  inputRef,
  addOn = null,
  trailingAddOn = null,
  inlineAddOn = null,
  inlineTrailingAddOn = null,
  ...rest
}) => {
  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus()
    }
  }, [inputRef])
  return (
    <FormGroup
      controlId={controlId}
      labelText={labelText}
      errors={errors && errors[controlId]}
      tooltip={tooltip}
    >
      {addOn}
      {inlineAddOn}
      <TextInput
        placeholder={placeholder}
        value={formData[controlId]}
        onChange={
          onInputChange
            ? (e) => {
                onInputChange(controlId, e.target.value)
              }
            : undefined
        }
        ref={inputRef}
        hasAddOn={!!addOn}
        hasTrailingAddOn={!!trailingAddOn}
        {...rest}
      />
      {trailingAddOn}
      {inlineTrailingAddOn}
    </FormGroup>
  )
}

export default FormGroupTextInput
